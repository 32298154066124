#ProgressBarContainer {
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(2px);
  background-color: rgba(136, 136, 136, 0.7);
  z-index: 1000000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#ProgressBarBox {
  background: rgb(227, 230, 255);
  padding: 50px;
  width: 50%;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgb(136, 136, 136);
}

#ProgressBarText {
  color: rgb(59, 42, 42);
  text-align: center;
}

#ProgressBarBar {
  width: 100%;
}
