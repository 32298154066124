#AssessmentOverlay #AssessmentOverlayBox #AssessmentData {
  height: 100%;
  width: 95%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentData h3 {
  margin-bottom: 10px;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentData select {
  margin-left: 5px;
  height: 25px;
  width: 150px;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentData button {
  margin-left: 0px;
  height: 35px;
  width: 125px;
  font-weight: bold;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentData .row {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentData .row span {
  margin-left: 5px;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentData .altColor {
  background-color: rgb(227, 230, 255);
}
