#AssessmentsManagement {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 65px);
  width: 100vw;
}

#AssessmentsManagement h1 {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  margin: 5px;
}

#AssessmentsManagement h3 {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  margin: 5px;
  align-self: flex-start;
  margin-left: 5%;
}

#AssessmentsManagement h3 button {
  background-color: rgba(35, 42, 63, 1);
  padding: 8px;
  color: white;
  font-size: 1.2rem;
  border: 0px;
  display: flex;
  align-items: center;
}

#AssessmentsManagement h3 button h4 {
  margin: 0;
  margin-left: 10px;
}

#AssessmentsManagement h3 button:hover {
  background-color: rgb(68, 81, 117);
  cursor: pointer;
}

#AssessmentsManagement #AssessmentLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.ag-header-cell-label {
  justify-content: center;
}
