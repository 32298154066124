#AssessmentOverlay #AssessmentOverlayBox #AssessmentOptions {
  height: 100%;
  margin-top: 10px;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentOptions h3 {
  margin-bottom: 10px;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentOptions select {
  margin-left: 5px;
  height: 25px;
  width: 150px;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentOptions button {
  margin-left: 0px;
  height: 35px;
  width: 125px;
  font-weight: bold;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentOptions > button {
  border-radius: 0 0 20px 20px;
  background-color: rgb(227, 230, 255);
  color: black;
  text-align: center;
  width: 100%;
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentOptions > button:hover {
  background-color: rgb(193, 199, 255);
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentOptions .disabled {
  background-color: rgb(239, 241, 253);
  color: rgb(95, 95, 95);
}

#AssessmentOverlay #AssessmentOverlayBox #AssessmentOptions .disabled:hover {
  background-color: rgb(239, 241, 253);
  cursor: not-allowed;
}
