#AssessmentOverlay {
  height: 100vh;
  width: 100vw;
  /* backdrop-filter: blur(2px); */
  z-index: 10000;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#AssessmentOverlay h1 {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  margin: 5px;
}

#AssessmentOverlay #AssessmentOverlayBox {
  height: 80%;
  width: 80%;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(219, 219, 219);
  /* box-shadow: 5px 5px 5px rgb(219, 219, 219); */
}

#AssessmentOverlay #AssessmentOverlayBox button {
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 60px;
  background-color: rgba(35, 42, 63, 1);
  color: white;
  border: none;
}

#AssessmentOverlay #AssessmentOverlayBox #InformationAndOptionsContainer #Information > button {
  margin: 0;
}

#AssessmentOverlay #AssessmentOverlayBox button:hover {
  background-color: rgb(68, 81, 117);
  cursor: pointer;
}

#AssessmentOverlay #AssessmentOverlayBox #InformationAndOptionsContainer {
  display: flex;
  text-align: left;
  height: 100%;
  width: 100%;
  margin: 10px;
}

#AssessmentOverlay #AssessmentOverlayBox #InformationAndOptionsContainer h3 {
  margin: 0px;
}

#AssessmentOverlay
  #AssessmentOverlayBox
  #InformationAndOptionsContainer
  #Information {
  display: flex;
  flex-direction: column;
  width: 45%;
}

#AssessmentOverlay
  #AssessmentOverlayBox
  #InformationAndOptionsContainer
  .ItemKey {
  font-weight: bold;
}

#AssessmentOverlay
  #AssessmentOverlayBox
  #InformationAndOptionsContainer
  #Options {
  width: 45%;
  height: 100%;
}
