#UserAssignments table {
  border: 1px solid hsl(0, 0%, 80%);
  border-spacing: 5px 5px;
  border-radius: 5px;
  width: 100%;
}

#UserAssignments tbody {
  height: 100%;
}

#UserAssignments table td {
  height: 150%;
}

#UserAssignments .tableContainer {
  max-height: 250px;
  overflow-y: auto;
}
